<template>
  <div>
    <div class="container-flex mx-auto">
      <div class="list-container">
        <!-- Call List -->
        <base-list
          :items="callItems"
          :fields="getFields()"
          :totalItems="totalItems"
          :slots="templates"
          :sort-by-prop="sortBy"
          :sort-desc-prop="sortDesc"
          :sort-direction="sortDirection"
          :loading="loading"
          :hide-filter-control="false"
          :asyncFilter="filterList"
          :advancedSearchActive="advancedSearchActive"
          :show-advanced-filter-control="true"
          :hover="true"
          @filter-changed="totalItems = $event.length"
          @page-changed="onPageSelection"
          @show-advanced-search="toggleAdvancedSearch"
          class="conversation-history"
        >
          <template slot="title">
            <div class="d-flex align-items-center justify-content-between w-100">
              <div class="title">
                {{ staticText.displayName }}
              </div>
              <div
                id="action-button-group-202301050153"
                :class="['d-flex align-items-center action-button-group', {'show': anyCallSelected}]"
              >
                <div v-if="canModifyCallsRealTag" class="mark-call-real">
                  <bao-checkbox
                    :value="areSelectedCallsReal"
                    :switchStyle="false"
                    :label="areSelectedCallsReal?staticText.unmarkCallsRealLabel:staticText.markCallsRealLabel"
                    @input="updateCallsRealTag($event)"
                  />
                </div>
                <b-btn
                  v-if="canUseBaoCoPilot"
                  id="download-calls-data-btn-202411010907"
                  variant="secondary"
                  class="mr-2 px-4 py-0 action-button d-flex align-items-center"
                  @click="downloadCallsData(selectedCalls)"
                >
                  <progress-indicator
                    v-if="isCallDownloading"
                    :small="true"
                    :showLoadingLabel="false"
                  />
                  <div
                    v-else
                    class="d-flex align-items-center"
                  >
                    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:class="'mr-2'" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M0 10.875a.5.5 0 01.5-.5h11a.5.5 0 01.5.5v.625a.5.5 0 01-.5.5H.5a.5.5 0 01-.5-.5v-.625zM8.208.5a.5.5 0 00-.5-.5H4.292a.5.5 0 00-.5.5v4.466h-1.49a.5.5 0 00-.34.866L6 9.592l4.038-3.76a.5.5 0 00-.34-.866h-1.49V.5z" fill="#35375B" opacity=".8"/></svg>
                    {{ staticText.downloadDataLabel }}
                  </div>
                </b-btn>

                <b-btn
                  id="show-call-summary-btn-202012241138"
                  variant="secondary"
                  class="mr-2 px-4 py-0 action-button"
                  :disabled="isSharedCallSelected"
                  @click="showCallsSummary(selectedCalls)"
                >
                  <i class="fa fa-search" aria-hidden="true"></i>
                  {{ staticText.showDetailsModalBtn }}
                </b-btn>

                <bao-delete-button
                  id="delete-btn-for-multiple-202012241138"
                  :label="staticText.deleteCallBtn"
                  :disabled="isSharedCallSelected"
                  @delete="deleteCall(selectedCalls)"
                >
                  {{ staticText.warningModalText1 }}
                  {{ staticText.warningModalText2 }}
                </bao-delete-button>
              </div>
            </div>
          </template>

          <template #advancedSearchAction>
            <advanced-filters
              @filter-calls="filterCalls"
            />
          </template>

          <template slot="listActions">
            <div class="d-flex align-items-center justify-content-end flex-shrink-0">

              <div
                v-if="!!filtersOn.answers"
                class="mb-2 mt-2 d-flex justify-content-between align-items-center"
              >
                <span class="mr-1">
                  Current answer: {{ $route.query.answers }}
                </span>

                <b-btn variant="primary" @click="handleInput('answers', null)">
                  <i class="fas fa-trash"></i>
                </b-btn>
              </div>

              <div
                v-if="!!filtersOn.objections"
                class="mb-2 mt-2 d-flex justify-content-between align-items-center"
              >
                <span class="mr-1">
                  Current objection: {{ $route.query.objections }}
                </span>

                <b-btn
                  variant="primary"
                  @click="handleInput('objections', null)"
                >
                  <i class="fas fa-trash"></i>
                </b-btn>
              </div>
            </div>
          </template>

          <!-- Header row -->
          <template v-slot:head_selected>
            <b-form-checkbox
              v-model="allSelected"
              @change="toggleAllSelection"
              @click.native.stop
            >
            </b-form-checkbox>
          </template>

          <template v-slot:head_counterparts>
            {{ staticText.counterpartsLabel }}
          </template>

          <template v-slot:head_bao_values>
            <div
              id="bao-value-label-202012241138"
              class="d-flex justify-content-between"
            >
              {{ staticText.baovalueLabel }}
            </div>
          </template>

          <template v-slot:head_call_time>
            {{ staticText.datesLabel }}
          </template>

          <template v-slot:head_talkscript_name>
            {{ staticText.playbooksLabel }}
          </template>

          <template v-slot:head_created_by_name>
            {{ staticText.userLabel }}
          </template>

          <template v-slot:selected="data">
            <b-form-checkbox
              :id="'checkbox' + data.cell.id"
              v-model="data.cell.selected"
              @change="toggleRowSelection(data.cell)"
            >
            </b-form-checkbox>
          </template>

          <template v-slot:counterparts="data">
            <div
              v-for="(cp, index) in data.cell.counterparts"
              :key="index"
              class="counterparts-list"
            >
              <component
                :is="getCrmLogo(cp.crm_service)"
                class="crm-logo crm-logo__slate mr-2"
              />
              <i :class="counterpartAppInfo.getCounterpartIcon(cp.type)"></i>
              <span class="ml-1">{{ cp.name }}</span>
            </div>
          </template>

          <template v-slot:bao_values="data">
            <div v-if="data.cell.bao_values && data.cell.bao_values.length > 0">
              <div>
                {{ data.cell.bao_values[0].value }} /
                {{ data.cell.bao_values[0].max_value }}
                <span class="ml-2">
                  ({{
                    (
                      (data.cell.bao_values[0].value /
                        data.cell.bao_values[0].max_value) *
                      100
                    ).toFixed(0)
                  }}
                  %)
                </span>
              </div>
            </div>
          </template>

          <template v-slot:call_time="data">
            <bao-date-time-display
              :value="data.cell.call_time"
            ></bao-date-time-display>
          </template>

          <template v-slot:actions="data">
            <div class="d-flex justify-content-around">
              <div :id="'call-edit-btn-div-202408081242-' + data.cell.id"
                class="d-flex">
                <!-- edit -->
                <b-btn
                    :id="'call-edit-btn-202408141603-' + data.cell.id"
                    variant="secondary"
                    class="action-button mr-1 d-flex align-items-center"
                    :disabled="!isItemEditable(data.cell)"
                    @click="editCall(data.cell)"
                  >
                  <img src="@/assets/svgs/edit-icon.svg" height="16px" width="16px" svg-inline/>
                  </b-btn>
              </div>
              <b-tooltip
                v-if="!isItemEditable(data.cell)"
                :target="'call-edit-btn-div-202408081242-' + data.cell.id" triggers="hover"
                custom-class="custom-tooltip"
              >
                <div class="d-flex align-items-center">
                  {{staticText.disableEditBtnLabel}}
                </div>
              </b-tooltip>
              <div :id="'call-delete-div-202408081243' + data.cell.id"
                class="ml-2">
                <!-- delete -->
                <bao-delete-button
                    :id="'call-delete-btn-202408081242-' + data.cell.id"
                    variant="secondary"
                    :disabled="!isItemEditable(data.cell)"
                    @delete="deleteCall([data.cell])"
                  >
                    {{ staticText.warningModalText1 }}
                    {{ staticText.warningModalText2 }}
                </bao-delete-button>
              </div>
              <b-tooltip
                v-if="!isItemEditable(data.cell)"
                :target="'call-delete-div-202408081243' + data.cell.id" triggers="hover"
                custom-class="custom-tooltip"
              >
                <div class="d-flex align-items-center">
                  {{staticText.disableDeleteBtnLabel}}
                </div>
              </b-tooltip>
              <div :id="'details-btn-202408081243-' + data.cell.id"
                class="d-flex"  
                >
                <b-btn
                  variant="secondary"
                  class="font-size-14 ml-2 pl-3 pr-4 py-2 opacity-1 action-button no-wrap-style d-flex align-items-center"
                  :disabled="!isItemEditable(data.cell)"
                  @click="showCallsSummary({ call: data.cell })"
                >
                  {{ staticText.showDetailsBtn }}
                </b-btn>
              </div>
                <b-tooltip
                  v-if="!isItemEditable(data.cell)"
                  :target="'details-btn-202408081243-' + data.cell.id" triggers="hover"
                  custom-class="custom-tooltip"
                >
                  <div class="d-flex align-items-center">
                    {{staticText.disableQuickViewLabel}}
                  </div>
                </b-tooltip>  
                <!-- details -->
                <b-btn
                  v-if="canUseBaoAudio"
                  :id="'call-feedback-btn-202408081244-' + data.cell.id"
                  variant="secondary"
                  class="font-size-14 ml-2 pl-3 pr-4 py-2 opacity-1 action-button no-wrap-style"
                  >
                  <router-link
                    :id="'call-feedback-router-202408081244' + data.cell.id"
                    target="_blank"
                    :to="`/calls/${data.cell.id}`"
                    class="text-slate no-underline"
                    >
                      {{ staticText.showCallFeedbackLabel }}
                  </router-link>
                </b-btn>

            </div>
            
          </template>

          <template v-slot:talkscript_name="data">
            <div class="break-word">
              {{ data.cell.talkscript_name }}
            </div>
          </template>
        </base-list>

        <!-- Call Summary Modal -->
        <div v-if="callsForSummary">
          <b-modal
            id="summary-modal-202012241138"
            body-class="modal-body--custom"
            v-model="showSummary"
            size="xl"
            hide-footer
            @hide="hideSummary"
          >
            <template v-slot:modal-header-close>
              <img src="../../../public/img/icons/close-icon.svg"/>
            </template>
            <template v-slot:modal-title>
              <img
                src="../../../public/img/icons/conversation-icon.svg"
                class="mr-2 w-16px"
              />
              <span>{{ staticText.conversationModalTitle }}</span>
            </template>
            <call-summary
              v-for="(selectedCall, index) in Object.values(callsForSummary)"
              :key="index"
              :call-id="selectedCall"
              :hide-controls="Object.keys(callsForSummary).length !== 1"
              @deleted="handleCallDeletedFromSummary"
            ></call-summary>
          </b-modal>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import appInfo from "./index"
import axios from "axios"
import BaseList from "../base/BaseList"
import CallSummary from "./CallSummary"
import BaoDeleteButton from "@/apps/base/BaoDeleteButton"
import { mapActions, mapGetters, mapMutations } from "vuex"
import BaoDateTimeDisplay from "@/apps/base/BaoDateTimeDisplay"
import counterpartAppInfo from "@/apps/counterpart/index"
import AdvancedFilters from "./CallListComponents/AdvancedFilters.vue"
import ProgressIndicator from "../base/ProgressIndicator.vue"
import CrmLogoMixin from "@/apps/call_history/CallSummaryComponents/CrmLogoMixin"
import BaoCheckbox from "@/apps/base/BaoCheckbox.vue"
import { updateCallsRealTagApi } from "@/apps/call_history/api.js"
import { CALL_TYPE_REAL } from "@/apps/base/constants"

export default {
  name: "CallList",
  mixins: [CrmLogoMixin],
  components: {
    BaseList,
    CallSummary,
    BaoDeleteButton,
    BaoDateTimeDisplay,
    AdvancedFilters,
    ProgressIndicator,
    BaoCheckbox
  },
  data () {
    return {
      counterpartAppInfo,
      axios,
      timer: 0,
      callItems: [],
      totalItems: 0,
      baseUrl: appInfo.route.path + "/",
      detail_item_id: "",
      sortBy: "call_time",
      sortDesc: true,
      sortDirection: "desc",
      templates: [
        {
          field: "talkscript_name",
          head: "talkscript_name"
        },
        {
          head: "created_by_name"
        },
        {
          field: "selected",
          head: "selected"
        },
        {
          field: "counterparts",
          head: "counterparts"
        },
        {
          field: "call_time",
          head: "call_time"
        },
        {
          field: "bao_values",
          head: "bao_values"
        },
        {
          field: "actions"
        }
      ],
      selectedItem: null,
      allSelected: false,
      showSummary: false,
      loading: false,
      currentDisplayedItems: [],
      hideControls: false,
      reload: true,
      filtersOn: {
        objections: false,
        answers: false,
        any_contains: false
      },
      staticTextDefault: {
        displayName: "Conversation History",
        editCallBtn: "Edit",
        deleteCallBtn: "Delete",
        conversationModalTitle: "Quick View",
        warningModalText1: "Deleting a conversation is an irreversible action.",
        warningModalText2: "Are you sure you want to delete the conversation?",
        counterpartsLabel: "Counterparts",
        datesLabel: "Date & Time",
        playbooksLabel: "Playbook",
        userLabel: "User",
        selectedLabel: "All",
        baovalueLabel: "bao Value",
        tooltipMoreText: "More",
        actionLabel: "Actions",
        removeFilter: "Remove filter",
        showDetailsBtn: "Quick View",
        showDetailsModalBtn: "Show Quick View",
        showCallFeedbackLabel: "Details",
        downloadDataLabel: "Download Data",
        disableEditBtnLabel: "This call cannot be edited because it is from another user.",
        disableDeleteBtnLabel: "This call cannot be deleted because it is from another user.",
        disableQuickViewLabel: "The quick view cannot be opened, because the call is from another user.",
        markCallsRealLabel: "Mark as real call",
        unmarkCallsRealLabel: "Unmark as real call"
      },
      callsForSummary: null,
      counterpartModal: false,
      selectedCounterpart: null,
      advancedSearchActive: false,
      isCallDownloading: false,
      realCallData: false
    }
  },
  computed: {
    ...mapGetters({
      selectedCalls: "calls/getSelectedCalls",
      canUseBaoValue: "auth/canUseBaoValue",
      canUseBaoAudio: "auth/canUseBaoAudio",
      canUseBaoCoPilot: "auth/canUseBaoCoPilot",
      canUserModify: "callSummaryStore/canUserModify",
      currentUser: "auth/user",
      canModifyCallsRealTag: "auth/canModifyCallsRealTag"
    }),
    staticText () {
      return this.$store.getters["I18nStore/getI18n"](
        this.$options.name,
        this.staticTextDefault
      )
    },
    areSelectedCallsReal () {
      // if all the sected calls are real then return true else false
      const selectedCalls = Object.values(this.selectedCalls)
      return selectedCalls.every(call => call.tags.some(tag => tag.name === CALL_TYPE_REAL))
    },
    maxBaoValue () {
      return this.callItems.reduce((sum, currentCall) => {
        if (
          currentCall.bao_values &&
          currentCall.bao_values.length > 0 &&
          currentCall.bao_values[0].max_value
        ) {
          return sum + currentCall.bao_values[0].max_value
        }
        return sum
      }, 0)
    },
    filterNotEmpty () {
      const queryParamCount = Object.keys(this.$route.query).filter(
        (key) => key !== "currentPage"
      ).length // removing currentPage key
      return queryParamCount > 0
    },
    anyCallSelected () {
      return this.selectedCalls && Object.keys(this.selectedCalls).length > 0
    },
    isSharedCallSelected () {
      // check if any of the selected calls is shared one. if so disable the quick view and delete button on top of the page of call list view
      return Object.values(this.selectedCalls).some(item => 
       item.shared_with && item.shared_with.users && item.shared_with.users.some(user => user.pk === this.currentUser.pk)
      );
    }
  },
  watch: {
    "$route.query" () {
      this.extractCallIdsForSummary()
      if (this.reload) {
        this.onFilterChange()
      } else {
        this.reload = true
      }
    }
  },
  mounted () {
    this.setUp()
  },
  methods: {
    ...mapActions({
      onCallSelected: "calls/callSelected",
      onCallDeselected: "calls/callDeselected",
      clearSelectedCalls: "calls/clearSelectedCalls",
      deleteSelectedCalls: "calls/deleteSelectedCalls"
    }),
    ...mapMutations({
      setError: "globalError/setError"
    }),
    setUp () {
      this.$nextTick(function () {
        // Code that will run only after the
        // entire view has been rendered
        this.clearSelectedCalls()
        this.timer = 0
        setTimeout(() => {
          this.getItems(this.getParams())
          this.extractCallIdsForSummary()
        }, 150)
      })
    },
    isItemEditable(item){
      return item.can_modify
    },
    async updateCallsRealTag (value) {
      const payload = {
        call_ids: Object.keys(this.selectedCalls),
        mark_calls_real: value
      }
      // update real tag to calls
      await updateCallsRealTagApi(payload)
      // get the updated data
      await this.getItems(this.getParams())

      // update the selecteds after change in call->tags
      this.callItems.forEach(call => {
        if (Object.keys(this.selectedCalls).includes(String(call.id))) {
          this.onCallDeselected(call)
          this.onCallSelected(call)
        }
      })
    },
    toggleAdvancedSearch (value) {
      this.advancedSearchActive = value
    },
    handleInput (key, value) {
      const query = { ...this.$route.query }
      if (!value) {
        delete query[key]
        this.$set(this.filtersOn, key, false)
      } else {
        query[key] = value
        this.$set(this.filtersOn, key, true)
      }
      query.currentPage = "1"
      this.$router.push({ query })
    },
    getParams () {
      return { ...this.$route.query }
    },
    filterList (filterValue) {
      this.handleInput("any_contains", filterValue)
    },
    onFilterChange () {
      this.getItems(this.getParams())
    },
    showCallsSummary (calls) {
      const callIds = Object.values(calls)
        .sort((a, b) => {
          return new Date(b.call_time) - new Date(a.call_time)
        })
        .map((item) => item.id)
      this.reload = false
      this.$router.push({
        query: { ...this.$route.query, ids: callIds.join() }
      })
    },
    hideSummary () {
      const query = Object.assign({}, this.$route.query)
      delete query.ids
      this.reload = false
      this.$router.push({ query })
    },
    toggleAllSelection () {
      this.$nextTick(() => {
        if (this.allSelected) {
          this.callItems = this.callItems.map((item) => {
            item.selected = true
            this.onCallSelected(item)
            return item
          })
        } else {
          this.callItems = this.callItems.map((item) => {
            this.onCallDeselected(item)
            item.selected = false
            return item
          })
        }
      })
    },
    toggleRowSelection (item) {
      return this.$nextTick().then(() => {
        if (item.selected) {
          this.onCallSelected(item)
          this.checkAllRowsSelected()
        } else {
          this.onCallDeselected(item)
          this.allSelected = false
        }
      })
    },
    deleteCall (calls) {
      const that = this
      return that
        .deleteSelectedCalls(calls)
        .then(() => {
          that.clearSelectedCalls()
          that.getItems(this.getParams())
        })
        .catch((error) => {
          that.setError(error)
        })
    },
    checkAllRowsSelected () {
      if (this.callItems.length === 0) {
        this.allSelected = false
      } else {
        this.allSelected = true
        this.callItems = this.callItems.map((call) => {
          if (this.selectedCalls[call.id]) {
            call.selected = true
          } else {
            this.allSelected = false
          }
          return call
        })
      }
    },
    onPageSelection (pageNumber) {
      this.$router.push({
        query: { ...this.$route.query, currentPage: pageNumber }
      })
    },
    async getItems (params = {}) {
      const that = this
      params.page = this.$route.query.currentPage
        ? this.$route.query.currentPage
        : 1
      params.page_size = this.$route.query.page_size
        ? this.$route.query.page_size
        : 10
      that.loading = true
      return that.axios
        .get("/api/calls", { params })
        .then((response) => {
          that.callItems = response.data.results
          that.totalItems = response.data.count
          that.loading = false
          that.checkAllRowsSelected()
        })
        .catch((error) => {
          that.setError(error)
        })
    },
    editCall (item) {
      this.selectedItem = item
      this.$router.push({ path: `/conversation/${this.selectedItem.id}?editConversation=true` })
    },
    getFields () {
      const defaultFields = [
        {
          key: "selected",
          label: this.staticText.selectedLabel
        },
        {
          key: "counterparts",
          label: this.staticText.counterpartsLabel
        },
        {
          key: "call_time",
          label: this.staticText.datesLabel
        },
        {
          key: "talkscript_name",
          label: this.staticText.playbooksLabel
        },
        {
          key: "created_by_name",
          label: this.staticText.userLabel
        },
        {
          key: "actions",
          label: this.staticText.actionLabel
        }
      ]
      if (this.canUseBaoValue) {
        defaultFields.splice(2, 0, {
          key: "bao_values",
          label: this.staticText.baovalueLabel
        })
      }
      return defaultFields
    },
    extractCallIdsForSummary () {
      if (this.$route.query.ids) {
        this.callsForSummary = this.$route.query.ids
          .split(",")
          .map((num) => parseInt(num))
        this.showSummary = true
      }
    },
    handleCallDeletedFromSummary () {
      this.showSummary = false
      this.onFilterChange()
    },
    async filterCalls (activeFilters) {
      if (
        Object.keys(activeFilters).length && !await this.areObjectsEqual(this.$route.query, activeFilters)
      ) {
        await this.$router.replace({ query: activeFilters })
      } else if (
        Object.keys(this.$route.query).length
      ) await this.$router.replace({ query: null })
    },
    async downloadCallsData (selectedCalls) {
      this.isCallDownloading = true
      try {
        const { data } = await this.axios.post("/api/calls/get_call_data_for_analysis", { call_ids: Object.keys(selectedCalls) })
        this.downloadObjectAsJson(data, `CallData-${Date.now()}`)
      } catch (error) {
        console.error(error)
      }
      this.isCallDownloading = false
    },
    downloadObjectAsJson (exportObj, exportName) {
      const dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(exportObj))
      const downloadAnchorNode = document.createElement("a")
      downloadAnchorNode.setAttribute("href", dataStr)
      downloadAnchorNode.setAttribute("download", exportName + ".json")
      document.body.appendChild(downloadAnchorNode) // required for firefox
      downloadAnchorNode.click()
      downloadAnchorNode.remove()
    }
  }
}
</script>

<style scoped lang="scss">
.opacity-1 {
  opacity: 1;
}

.w-16px {
  width: 16px;
}

.no-underline {
  text-decoration: none !important;
}

.action-button-group {
  opacity: 0;
  pointer-events: none;
  transition: 0.3s ease-in;

  &.show {
    opacity: 1;
    pointer-events: unset;
  }
}

.counterparts-list {
  word-break: break-word;
  margin-bottom: 4px;

  &:last-child {
    margin-bottom: 0;
  }
}

.ml-n-24 {
  margin-left: -24px;
}

:deep(.modal-body--custom) {
  background: $background;
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}

.custom-tooltip{
  :deep(.tooltip-inner){
    padding: 0 !important;
  }
  :deep(.tooltip .tooltip-inner){
    padding: 0 !important;
  }
}

.no-wrap-style{
  white-space: nowrap;
}

.disable-div{
  pointer-events: none
}  
.mark-call-real{
  background-color: #2d2d520f;
  border-radius: 12px;
  padding: 12px 14px;
  margin-right: 8px;
}
</style>
